import React, {useEffect, useState} from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import Img from 'gatsby-image';
import {Column, Columns} from 'bloomer';
import {ContObraComp} from '../../ContObra';
import {GlobalStylePage, ContArrow, ContImgHombreVolando, ImgHombreVolando, ContImgMano, ImgMano} from './styles';
import {ArrowNavTop, ArrowNavRight, ArrowNavBottom, ArrowNavLeft} from '../../ArrowsNavigation';
import {ContImgLeft, ContImgRight, GlobalStyle} from '../../../styles/GlobalStyles';
import {SpeechBubbleMini} from '../../SpeechBubbles/SpeechBubbleMini';
import {SpeechBubble} from '../../SpeechBubbles';
import useSound from 'use-sound';
import {gsap} from "gsap";
import imgHombreVolando from '../../../images/obras/obra-9/hombre-volando.png';
import imgMano from '../../../images/obras/obra-9/mano.png';
import soundHombreVolando from '../../../images/obras/obra-7/naturaleza.mp3';
import imgLeft from '../../../images/obras/obra-8/obra.jpeg';
import imgRight from '../../../images/obras/obra-10/obra.png';
import {BtnReplay} from '../../BtnReplay';
import {BtnParrilla} from '../../BtnParrilla';

const arNav = {
  top: null,
  right: 10,
  bottom: null,
  left: 8,
};

const Page09Comp = () => {
  const [showArrows, setShowArrows] = useState(false);
  const [initSpeechBubbleMini, setInitSpeechBubbleMini] = useState(false);
  const [initSpeechBubble, setInitSpeechBubble] = useState(false);
  const [initAnimatedHombreVolando, setInitAnimatedHombreVolando] = useState(false);
  const [initAnimatedMano, setInitAnimatedMano] = useState(false);
  const [initsoundHombreVolando, setInitsoundHombreVolando] = useState(false);
  const [reLaunchAll, setReLaunchAll] = useState(false);

  /*const data = useStaticQuery(graphql`
    {
      file(relativePath: {eq: "obras/obra-1/obra.jpeg"}) {
        childImageSharp {
          fixed(width: 340, height: 670) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);*/
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "obras/obra-9/obra.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 750, maxHeight: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const mouseOver = () => {
    setShowArrows(true);
  };
  const mouseLeaver = () => {
    setShowArrows(false);
  };

  const fnReLaunchAll = () => {
    fnReLaunchHombreVolando(0);
    fnReLaunchMano(0);
    setReLaunchAll(!reLaunchAll);
  }

  const fnReLaunchHombreVolando = (noReplay = 1) => {
    setInitAnimatedHombreVolando(false);
    setInitsoundHombreVolando(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedHombreVolando(true);
      }, 1000);
    }
  };
  const fnReLaunchMano = (noReplay = 1) => {
    setInitAnimatedMano(false);
    // setInitSoundMano(false);

    if(noReplay) {
      setTimeout(function () {
        setInitAnimatedMano(true);
      }, 1000);
    }
  };

  const fnAnimateHombreVolando = () => {
    return (
      <ContImgHombreVolando className={'img-animated'} onClick={() => fnReLaunchHombreVolando()}>
        {
          initAnimatedHombreVolando &&
          <ImgHombreVolando
            id={'animate-hombre-volando'}
            className={'animate-hombre-volando'}
            src={imgHombreVolando}
            alt="Hombre"
          />
        }
      </ContImgHombreVolando>
    )
  }
  const fnAnimateMano = () => {
    return (
      <ContImgMano className={'img-animated'} onClick={() => fnReLaunchMano()}>
        {
          initAnimatedMano &&
          <ImgMano
            id={'animate-mano'}
            className={'animate-mano'}
            src={imgMano}
            alt="Hombre"
          />
        }
      </ContImgMano>
    )
  }

  const fnSpeechBubbleMini = () => {
    return (
      <>
        {
          initSpeechBubbleMini &&
          <SpeechBubbleMini
            id={'speech-bubble-mini'}
            className={'speech-bubble-mini'}
            onClick={() => setInitSpeechBubble(true)}
          />
        }
      </>
    )
  }
  const fnSpeechBubble = () => {
    return (
      <>
        {
          initSpeechBubble &&
          <SpeechBubble
            id={'speech-bubble'}
            className={''}
            classClose={'close-top-left'}
            onClick={() => setInitSpeechBubble(false)}
          >
            <div> En la época oscura del año.</div>
            <div>Entre derretirse y congelarse.</div>
            <div>La savia del alma tiembla.</div>
          </SpeechBubble>
        }
      </>
    )
  }

  const fnLeft = () => {
    return <ContImgLeft src={imgLeft} />
  }
  const fnRight = () => {
    return <ContImgRight src={imgRight} />
  }

  useEffect(() => {
    setTimeout(function (){
      setInitAnimatedHombreVolando(true);
    }, 3000);

    setTimeout(function (){
      setInitAnimatedMano(true);
    }, 6000);

    setTimeout(function (){
      setInitSpeechBubbleMini(true);
    }, 9000);
  },[reLaunchAll]);

  const [playHombreVolando, { stopHombreVolando }] = useSound(
    soundHombreVolando,
    { volume: 0.3 }
  );

  useEffect(() => {
    const windowWrap = gsap.utils.wrap(0, window.innerWidth);
    const winWidth = window.innerWidth;
    let arPosMenFly = {};
    let arPosMano = {};

    if(winWidth < 767){
      arPosMenFly = {x: -120, y: -120}
      arPosMano = {y: 120}
    } else if(winWidth < 1023){
      arPosMenFly = {x: -250, y: -250}
      arPosMano = {y: 200}
    } else if(winWidth < 1215){
      arPosMenFly = {x: -300, y: -330}
      arPosMano = {y: 200}
    } else if(winWidth < 1407){
      arPosMenFly = {x: -300, y: -300}
      arPosMano = {y: 200}
    } else if(winWidth < 1919){
      arPosMenFly = {x: -400, y: -400}
      arPosMano = {y: 330}
    } else {
      arPosMenFly = {x: -600, y: -450}
      arPosMano = {y: 430}
    }

    if(initAnimatedHombreVolando){
      /*gsap.fromTo(".animate-hombre-volando", 2, {
        x: 800,
        y: 800
      }, {
        x: 0,
        y: 0
      }, "first");*/
      gsap.to(".animate-hombre-volando", {
        x: arPosMenFly.x,
        y: arPosMenFly.y,
        duration: 3,
        delay: 0,
      });

      if(!initsoundHombreVolando){
        playHombreVolando();
        setInitsoundHombreVolando(true);
      }
    }

    if(initAnimatedMano){
      /*gsap.fromTo(".animate-mano", 2, {
        y: 0,
        scaleY: 0,
        skewY: 0,
      }, {
        y: 200,
        scaleX: 3,
        scaleY: 3,
        skewX: 0,
        skewY: 0,
      }, "second");*/
      gsap.to('.animate-mano', {
        y: arPosMano.y,
        scaleX: 3,
        scaleY: 3,
        skewX: 3,
        skewY: 3,
        delay: 1,
        duration:3,
        ease:"linear",
      });

      /*if(!initsoundTriceratops){
        setTimeout(function(){
          playTriceratops();
          setInitsoundTriceratops(true);
        }, 2000);
      }*/
    }

  }, [initAnimatedHombreVolando, initAnimatedMano]);

  return (
    <ContObraComp>
      <BtnParrilla/>
      <BtnReplay onClick={() => fnReLaunchAll()}/>
      <div className={'columns-padding-no cont-obra-height'} onMouseOver={mouseOver} onMouseLeave={mouseLeaver}>
        <GlobalStyle/>
        <GlobalStylePage/>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavTop toPage={arNav.top}/>}
            </ContArrow>
          </Column>
        </Columns>

        <Columns className="columns-responsive columns-obra-height flex-center" isMultiline={false} isCentered={true}>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavLeft toPage={arNav.left}/>}
            </ContArrow>
          </Column>

          <Column isSize={{mobile: 10, tablet: 10, desktop: 10, widescreen: 10}} className={'flex-center container-animated'}>
            {/*<Img fixed={data.file.childImageSharp.fixed} />*/}
            <Img className={'img-obra'} fluid={data.placeholderImage.childImageSharp.fluid}/>
            {fnAnimateHombreVolando()}
            {fnAnimateMano()}
            {fnSpeechBubbleMini()}
            {fnSpeechBubble()}
            {fnLeft()}
            {fnRight()}
          </Column>

          <Column isSize={{mobile: 1, tablet: 1, desktop: 1, widescreen: 1}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavRight toPage={arNav.right}/>}
            </ContArrow>
          </Column>

        </Columns>

        <Columns className="columns-responsive" isMultiline={false} isCentered={true}>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}} className={'flex-center'}>
            <ContArrow>
              {showArrows && <ArrowNavBottom toPage={arNav.bottom}/>}
            </ContArrow>
          </Column>
        </Columns>
      </div>
    </ContObraComp>
  );
};

export default Page09Comp;
