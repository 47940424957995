import styled, {createGlobalStyle} from 'styled-components';

export const GlobalStylePage = createGlobalStyle`
  @media(min-width: 300px) and (max-width: 767px){
    .img-obra{
      width: 100%;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -315px;
      margin-left: 100px;
    }
  }
  @media(min-width: 768px) and (max-width: 1023px){
    .img-obra{
      width: 570px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -410px;
      margin-left: 265px;
    }
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    .img-obra{
      width: 786px;
      height: 100%;
    }
    .speech-bubble-mini{
      margin-top: -540px;
      margin-left: 380px;
    }
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    .img-obra{
      width: 400px;
      height: 115%;
    }
    .speech-bubble-mini{
      margin-top: 120px;
      margin-left: 180px;
    }
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    .img-obra{
      width: 620px;
      height: 976px;
    }
    .speech-bubble-mini{
      margin-top: 120px;
      margin-left: 280px;
    }
  }
  @media(min-width: 1920px){
    .img-obra{
      width: 720px;
      height: 1150px;
    }
    .speech-bubble-mini{
      margin-top: 120px;
      margin-left: 330px;
    }
  }
`
export const ContArrow = styled.div`
  width: 100%;
  height: 40px;
  z-index: 999;
`
export const ContImgHombreVolando = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 140px;
    height: auto;
    margin-top: -138px;
    margin-left: 110px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 250px;
    height: auto;
    margin-top: -70px;
    margin-left: 243px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 350px;
    height: auto;
    margin-top: -188px;
    margin-left: 278px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 240px;
    height: auto;
    margin-top: 109px;
    margin-left: 300px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 300px;
    height: auto;
    margin-top: 115px;
    margin-left: 350px;
  }
  @media(min-width: 1920px){
    width: 370px;
    height: auto;
    margin-top: 85px;
    margin-left: 565px;
  }
`
export const ImgHombreVolando = styled.img`
  width: 100%;
  height: 100%;
`
export const ContImgMano = styled.div`
  z-index: 99;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 50px;
    height: auto;
    margin-top: -210px;
    margin-left: 0px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 85px;
    height: auto;
    margin-top: -173px;
    margin-left: 0px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 110px;
    height: auto;
    margin-top: -173px;
    margin-left: 0px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 70px;
    height: auto;
    margin-top: -230px;
    margin-left: 0px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 80px;
    height: auto;
    margin-top: -320px;
    margin-left: 0px;
  }
  @media(min-width: 1920px){
    width: 120px;
    height: auto;
    margin-top: -400px;
    margin-left: 0px;
  }
`
export const ImgMano = styled.img`
  width: 100%;
  height: 100%;
`
